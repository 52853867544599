import React, { Children } from 'react'
import styled, { css } from 'styled-components'
import Box, { BoxProps } from './Box'

// export type DirectionValues = 'row' | 'column'

export type JustifyValues =
  | 'start'
  | 'end'
  | 'center'
  | 'between'
  | 'around'
  | 'evenly'
  | 'left'
  | 'right'

export type AlignValues = 'stretch' | 'start' | 'end' | 'center' | 'baseline'

export type JustifyContentValues =
  | 'start'
  | 'end'
  | 'stretch'
  | 'center'
  | 'between'
  | 'around'
  | 'left'
  | 'right'

export interface FlexPorps extends BoxProps {
  column?: boolean
  reverse?: boolean
  wrap?: boolean | 'wrap-reverse'
  justify?: JustifyValues
  align?: AlignValues
  alignContent?: JustifyContentValues
  gap?: string
}

export interface FlexItemProps extends BoxProps {
  order?: number
  grow?: number
  shrink?: number
  basis?: string
  align?: AlignValues
}

export const StyledFlex = styled(Box)<Omit<FlexPorps, 'gap'>>`
  display: flex;
  flex-direction: ${p =>
    p.reverse ? `${p.column ? 'column' : 'row'}-reverse` : p.column ? 'column' : 'row'};
  ${p =>
    p.wrap &&
    css`
      flex-wrap: ${p.wrap === 'wrap-reverse' ? `wrap-reverse` : p.wrap ? 'wrap' : 'nowrap'};
    `};
  ${p =>
    p.justify &&
    css`
      justify-content: ${p.justify};
    `};
  ${p =>
    p.align &&
    css`
      align-items: ${p.align};
    `};
  ${p =>
    p.alignContent &&
    css`
      align-content: ${p.alignContent};
    `};
`

export const Item = styled.div<FlexItemProps>`
  ${p =>
    p.order &&
    css`
      order: ${p.order};
    `};
  ${p =>
    p.grow &&
    css`
      flex-grow: ${p.grow};
    `};
  ${p =>
    p.shrink &&
    css`
      flex-shrink: ${p.shrink};
    `};
  ${p =>
    p.basis &&
    css`
      flex-basis: ${p.basis};
    `};
  ${p =>
    p.align &&
    css`
      align-self: ${p.align};
    `};
`

const StyledGap = styled.div<{ basis: string }>`
  flex: 0 0 ${p => p.basis};
`

export const Flex: React.FC<FlexPorps> = ({ children, gap, ...rest }) => {
  let contents: any = children
  if (gap && gap !== 'none') {
    contents = []
    let firstIndex: number
    Children.forEach(children, (child, index) => {
      if (child) {
        if (firstIndex === undefined) {
          firstIndex = index
        } else {
          contents.push(<StyledGap key={`gap-${index}`} as={'div'} basis={gap} />)
        }
      }
      contents.push(child)
    })
  }
  return <StyledFlex {...rest}>{contents}</StyledFlex>
}

export const Row: React.FC<Omit<FlexPorps, 'column'>> = ({ children, ...rest }) => (
  <Flex {...rest}>{children}</Flex>
)

export const Col: React.FC<Omit<FlexPorps, 'column'>> = ({ children, ...rest }) => (
  <Flex column {...rest}>
    {children}
  </Flex>
)

export const Space: React.FC<Omit<FlexItemProps, 'grow'>> = ({ children, ...rest }) => (
  <Item grow={1} {...rest}>
    {children}
  </Item>
)

export default Flex
