import * as React from 'react'
import { Link, HeadFC } from 'gatsby'
import Layout from '~/components/Layout'
import Flex, { Col } from '~/components/Flex'
import Box from '~/components/Box'

const NotFoundPage = () => {
  return (
    <Layout>
      <Col w="100%" h="100%">
        <Col align="center" justify="center" mr="auto">
          <h1>Страница не найдена (404)</h1>
          <p>
            <Link to="/">Вернуться на главную</Link>
          </p>
        </Col>
      </Col>
    </Layout>
  )
}

export default NotFoundPage

export const Head: HeadFC = () => <title>Ошибка 404</title>
