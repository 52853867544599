import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { normalizeColor } from '~/styling'

export interface BoxProps extends HTMLAttributes<HTMLDivElement> {
  pd?: string
  mr?: string
  bg?: string
  c?: string
  bw?: string
  bs?: string
  bc?: string
  br?: string
  w?: string
  h?: string
  mnw?: string
  mnh?: string
  mxw?: string
  mxh?: string
}

const Box = styled.div<BoxProps>`
  ${p =>
    p.pd &&
    css`
      padding: ${p.pd};
    `}
  ${p =>
    p.mr &&
    css`
      margin: ${p.mr};
    `}
  ${p =>
    p.bg &&
    css`
      background: ${normalizeColor(p.bg)};
    `}
  ${p =>
    p.c &&
    css`
      color: ${normalizeColor(p.c)};
    `}
  ${p =>
    p.bw &&
    css`
      border-width: ${p.bw};
    `}
  ${p =>
    p.bs &&
    css`
      border-style: ${p.bs};
    `}
  ${p =>
    p.bc &&
    css`
      border-color: ${normalizeColor(p.bc)};
    `}
  ${p =>
    p.br &&
    css`
      border-radius: ${p.br};
    `}
  ${p =>
    p.w &&
    css`
      width: ${p.w};
    `}
  ${p =>
    p.h &&
    css`
      height: ${p.h};
    `}
  ${p =>
    p.mnw &&
    css`
      min-width: ${p.mnw};
    `}
  ${p =>
    p.mnh &&
    css`
      min-height: ${p.mnh};
    `}
  ${p =>
    p.mxw &&
    css`
      max-width: ${p.mxw};
    `}
  ${p =>
    p.mxh &&
    css`
      max-height: ${p.mxh};
    `}
`

export default Box
